import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";

const StyledRadio = styled(Radio)(() => ({
  color: "black",
  "&.Mui-checked": {
    color: "black",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 22,
  },
}));

const PaymentSelector = ({ show = false, localized, selected,options }) => {
  return show ? (
    <div className="section-container">
      <div className="section d-flex flex-column gap-4">
        <FormControl>
          <label id="payment-radio-buttons-group" className="heading3">
            {localized.FreightPaidOrCollectLabel}:
          </label>

          <RadioGroup name="payment-radio-buttons-group" value={selected}>
                      <FormControlLabel
                          label={options.label1}
                          control={<StyledRadio disableFocusRipple disableTouchRipple />}
                          disableTypography={true}
                          value={options.freightCode1}
                          sx={{ maxWidth: "fit-content" }}
            />

                      <FormControlLabel
                          label={options.label2}
                          control={<StyledRadio disableFocusRipple disableTouchRipple />}
                          disableTypography={true}
                          value={options.freightCode2}
                          sx={{ maxWidth: "fit-content" }}
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  ) : null;
};

export default PaymentSelector;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import CartService from '../../services/CartService';
import Notifications from '../../services/Notifications';
import * as cartActionsFunctions from '../../actions/cartActions';
import * as orderDocTypes from '../../helpers/OrderDocTypes';
import ItemsReview from './ItemsReview';
import DateHelper from "../../helpers/DateHelper";
import { useCustomers } from '../../hooks/CustomerContext';
import { useCartConfig } from '../../hooks/CartConfigContext';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import PricingHelper from '../../helpers/PricingHelper';

export const CartReviewOrder = props => {
    const { settingsList, storefront } = useStorefrontSettings();
    const cartOptions = useCartConfig();
    const { currentCustomer: selectedCustomer } = useCustomers();
    const dispatch = props.cartActions ? {} : useDispatch();
    const cartActions = props.cartActions ? props.cartActions : bindActionCreators(cartActionsFunctions, dispatch);
    const store = props.store ? props.store : useSelector((state) => state);
    const [cart, setCart] = useState(store.cart);
    // need a separate requestDate state variable because updating the shipping option also updates the request date at the same time
    const [requestDate, setRequestDate] = useState(store.cart.requestDate);

    const [user, setUser] = useState(store.user);
    const [settings, setSettings] = useState(store.settings);
    const [orderIsQuote, setOrderIsQuote] = useState(props.location.state.orderIsQuote);
    const [placingOrder, setPlacingOrder] = useState(false);
    const [isValid, setIsValid] = useState(((cart.freightHandlingCode !== "COL") || (!selectedCustomer.companyCode.startsWith('09'))) || false);
    const [showDropship, setShowDropship] = useState(false);
    const [requiredPO, setRequiredPO] = useState((cartOptions.childComponents.PONumber ? cartOptions.childComponents.PONumber.required : false) === true || (selectedCustomer && selectedCustomer.customerPORequired === "Y") || false);
    const [requiredPhoneNo, setRequiredPhoneNo] = useState((cartOptions.childComponents.PhoneNumber ? cartOptions.childComponents.PhoneNumber.required :false)=== true || false);
    const [requiredQuotePO, setRequiredQuotePO] = useState((cartOptions.childComponents.QuotePONumber ? cartOptions.childComponents.QuotePONumber.required : false) === true || false);
    const [dayAdder, setDayAdder] = useState(settingsList.StandardShippingDays);
    const [selectedFreightOptions, setSelectedFreightOptions] = useState([]);
    const [showAdditionalFreightOptions, setShowAdditionalFreightOptions] = useState(false);
    const [automaticFreightCode, setAutomaticFreightCode] = useState('');
    const [freightPaymentOptions, setFreightPaymentOptions] = useState([]);
    const localized = cartOptions.labels;
   
   
    //{ freightCode: "CP1", label: localized.Paid }, { freightCode: "COL", label: localized.Collected }
    const carriersUpdated = (carriers) => {        
        setCart({ ...cart, storefrontCarrierId: carriers.carrier1, storefrontCarrier2Id: carriers.carrier2 });
        //if storefrontsetting is on
        let freightCodeRequest = {
            storefrontNumber: storefront.number,
            customerId: selectedCustomer.id,
            amount: cart.netSubtotal,
            carrierId: carriers.carrier1,
            currency: cart.netCurrency
        }
        CartService.getFreightCodesFromCarrier(freightCodeRequest).then(res => {
            if (res) {

                updateFreightCodeSettings(res);
                setShowAdditionalFreightOptions(res.showExtraInformation);                
                
            }
            else {
                Notifications.error("Carrier Information Not Found");
            }
        });
        
      }

    const updateFreightCodeSettings = (res) => {
        var freightCode;
        var label1 = localized[res.freightCode1Label];
        var label2 = localized[res.freightCode2Label];
        if (res.isEligibleForSpecialPaymentTerms) {
            freightCode = res.freightCode1;
        }
        else {
            freightCode = res.freightCode2;
        }
        setCart({ ...cart, freightHandlingCode: freightCode });
        setAutomaticFreightCode(freightCode);
        setFreightPaymentOptions({ ...freightPaymentOptions, freightCode1: res.freightCode1, label1: label1, freightCode2: res.freightCode2, label2: label2 });        
    };

    const carrierIdSelected = () => {
        if ((cartOptions.childComponents.Carrier1.required === true) && !cart.storefrontCarrierId) {
            Notifications.error(`${localized.CarrierRequiredMessage}`);
            return false;
        }
        else if ((cartOptions.childComponents.Carrier2.required === true) && !cart.storefrontCarrier2Id) {
            Notifications.error(`${localized.CarrierRequiredMessage} (2)`);
            return false;
        }
        else {
            return true;
        }
    }

    const commentsUpdated = (comment) => {
        setCart({ ...cart, comments: comment });
    }

    const freightCollectOptionUpdated = (preferred, account, freightHandlingCode, valid) => {
        setIsValid(valid);
        let freightCollectDeliveryInstructions;
        if (freightHandlingCode === "COL") {
            freightCollectDeliveryInstructions = preferred + " " + account;
        }
        else {
            freightCollectDeliveryInstructions = "";
        }

        setCart({ ...cart, freightHandlingCode: freightHandlingCode, deliveryInstructions2: freightCollectDeliveryInstructions, preferredString: preferred, accountString: account });
        const orderReview = {
            comments: cart.comments,
            deliveryInstructions: cart.deliveryInstructions,
            deliveryInstructions2: freightCollectDeliveryInstructions,
            freightHandlingCode: freightHandlingCode,
            accountString: account,
            preferredString: preferred,
            poNumber: cart.poNumber,
            phoneNumber: cart.phoneNumber,
            requestDate: requestDate,
            storefrontCarrierId: cart.storefrontCarrierId,
            storefrontCarrier2Id: cart.storefrontCarrier2Id,
            shippingMethod: cart.shippingMethod
        };
        cartActions.UpdateOrderReview(orderReview);
    }

    const deliveryInstructionsUpdated = (instructions) => {
        setCart({ ...cart, deliveryInstructions: instructions });
    }

    const deliveryInstructions2Updated = (instructions) => {
        setCart({ ...cart, deliveryInstructions2: instructions });
    }

    const handleOrderPlacedResponse = (documentNumber) => { // action and reducer
        const freightCode = (user.currentUser.storefront === "8700" || user.currentUser.storefront === "08700") ? 'BRP' : selectedCustomer.freightHandlingCode;
        cartActions.ClearCart(user.currentUser.id, null, null, freightCode);
        // redirect to the OrderPlaced page
        props.history.push({ pathname: '/cart/confirmation', state: { IsQuote: orderIsQuote, DocumentNumber: documentNumber } });
    }

    const poNumberUpdated = (poNumber) => {
        setCart({ ...cart, poNumber: poNumber });
    }
    const phoneNumberUpdated = (phoneNumber) => {
        setCart({ ...cart, phoneNumber: phoneNumber });
    }
    const requestDateUpdated = (date, dayAdder) => {
        setRequestDate(date);
        setDayAdder(dayAdder);
    }

    const shippingMethodUpdated = (shippingMethod) => {
        setCart({ ...cart, shippingMethod: shippingMethod });
        const orderReview = {
            comments: cart.comments,
            deliveryInstructions: cart.deliveryInstructions,
            deliveryInstructions2: cart.deliveryInstructions2,
            accountString: cart.accountString,
            preferredString: cart.preferredString,
            freightHandlingCode: cart.freightHandlingCode,
            poNumber: cart.poNumber,
            phoneNumber: cart.phoneNumber,
            requestDate: requestDate,
            storefrontCarrierId: cart.storefrontCarrierId,
            storefrontCarrier2Id: cart.storefrontCarrier2Id,
            shippingMethod: shippingMethod
        };
        cartActions.UpdateOrderReview(orderReview);
    }

    const submitOrder = (isOCU = false) => {
        const useOrderType = settingsList.UseOrderType === "" ? orderDocTypes.SALES_ORDER : settingsList.UseOrderType;
        const nlaExFreightHandlingCode = PricingHelper.getIncoTerms(cart.netSubtotal);
        // Drop ship address check
        if (!orderIsQuote && showDropship && (!cart.dropshipAddress?.fullName || !cart.dropshipAddress.addressLine1 ||
            !cart.dropshipAddress.city || !cart.dropshipAddress.stateProvenceRegion || !cart.dropshipAddress.zipCode || !cart.dropshipAddress.country)) {
            Notifications.error(localized.DropshipAddressNotComplete);
        }
        // Phone Number check based on requiredPhoneNo setting
        else if (requiredPhoneNo && (!cart.phoneNumber || cart.phoneNumber.trim() === "")) {
            Notifications.error(localized.PhoneNoRequiredMessage);
        }
        else if (((orderIsQuote && requiredQuotePO) || (!orderIsQuote && requiredPO)) && (!cart.poNumber || cart.poNumber.trim() === "")) {
            Notifications.error(localized.PORequiredMessage);
        }

        else if (carrierIdSelected()) {
            setPlacingOrder(true);

            const order = CartService.getSubmitOrderBodyEComm({
                ...cart,
                docType: (!orderIsQuote) ? useOrderType : orderDocTypes.SALES_QUOTE,
                selectedCustomer: selectedCustomer,
                orderedBy: user.currentUser.email,
                implementationId: storefront.implementationId,
                freightHandlingCode: settingsList.UseOrderType === "SI" ? nlaExFreightHandlingCode?.key : cart.freightHandlingCode,
                selectedBranch: cart.selectedBranch
            });
            if (cart.shippingMethod) {
                let updatedRequestDate = requestDate;
                if (orderIsQuote) {
                    let businessDays = cartOptions.shippingOptions.find(item => item.method === cart.shippingMethod).businessDays;
                    updatedRequestDate = DateHelper.addBusinessDays(requestDate, businessDays, props.location.state.dateFormat);
                    requestDateUpdated(updatedRequestDate);
                }
                else {
                    order.requestedDate = updatedRequestDate;
                }
                order.requestDate = updatedRequestDate;
                order.priorityCode = cart.shippingMethod;
                order.deliveryInstruction2 = cart.deliveryInstructions2;
            }

            if (requiredPhoneNo) {
                let concatenatedString = localized.FreightOptionsSelected + (selectedFreightOptions.length > 0 ? selectedFreightOptions.join(', ') : '');
                order.comments = (selectedFreightOptions.length > 0 ? concatenatedString : "") + ' ' + localized.OrderPhoneNumber + cart.phoneNumber;
            }

            if (isOCU) {
                order.deliveryInstruction2 = `OCU ${order.deliveryInstruction2}`;
            }

            CartService.submitOrderEComm(order).then(res => {
                if (res) {
                    handleOrderPlacedResponse(res.documentNumber);
                }
                else {
                    Notifications.error(localized.OrderPlacementErrorMessage);
                    setPlacingOrder(false);
                }
            });
        }
    }

    const toggleDropship = () => {
        setShowDropship(!showDropship);
        if (showDropship === false) {
            updateDropshipAddress(null);
        }
    }

    const toggleReviewingCart = () => {
        window.scrollTo(0, 0);
        props.history.push({ pathname: '/cart'});
    }

    useEffect(() => {
        const orderReview = {
            comments: cart.comments,
            deliveryInstructions: cart.deliveryInstructions,
            deliveryInstructions2: cart.deliveryInstructions2,
            accountString: cart.accountString,
            preferredString: cart.preferredString,
            freightHandlingCode: cart.freightHandlingCode,
            poNumber: cart.poNumber,
            phoneNumber: cart.phoneNumber,
            requestDate: requestDate,
            storefrontCarrierId: cart.storefrontCarrierId,
            storefrontCarrier2Id: cart.storefrontCarrier2Id,
            shippingMethod: cart.shippingMethod,
        };
        cartActions.UpdateOrderReview(orderReview);
    }, [cart, cart.comments])

    const updateDropshipAddress = (dropship) => {
        setCart({ ...cart, dropshipAddress: dropship });
        cartActions.UpdateDropshipAddress(dropship);
    }

    const freightOptionUpdated = (description) => {
        if (selectedFreightOptions.includes(description)) {
            setSelectedFreightOptions(selectedFreightOptions.filter((optionId) => optionId !== description));
        } else {
            setSelectedFreightOptions([...selectedFreightOptions, description]);
        }
    }

    return (
        <ItemsReview
            currentUser={user.currentUser}
            orderIsQuote={orderIsQuote}
            showDropship={showDropship}
            requiredPO={requiredPO}
            requiredPhoneNo={requiredPhoneNo}
            placingOrder={placingOrder}
            isValid={isValid}
            dateFormat={props.location.state.dateFormat}
            comments={cart.comments}
            currency={cart.netCurrency}
            deliveryInstructions={cart.deliveryInstructions}
            deliveryInstructions2={cart.deliveryInstructions2}
            accountString={cart.accountString}
            preferredString={cart.preferredString}
            items={cart.items}
            poNumber={cart.poNumber}
            phoneNumber={cart.phoneNumber}
            requestDate={requestDate}
            dayAdder={dayAdder}
            freightHandlingCode={cart.freightHandlingCode}
            showAdditionalFreightOptions={showAdditionalFreightOptions}
            freightPaymentOptions={freightPaymentOptions}
            automaticFreightCode={automaticFreightCode}
            selectedCustomer={selectedCustomer}
            shippingMethod={cart.shippingMethod}
            storefrontCarrierId={cart.storefrontCarrierId}
            storefrontCarrier2Id={cart.storefrontCarrier2Id}
            subTotal={cart.netSubtotal}
            dropshipAddress={cart.dropshipAddress}
            updateDropshipAddress={updateDropshipAddress}
            toggleReviewingCart={toggleReviewingCart}
            toggleDropship={toggleDropship}
            submitOrder={submitOrder}
            shippingMethodUpdated={shippingMethodUpdated}
            requestDateUpdated={requestDateUpdated}
            poNumberUpdated={poNumberUpdated}
            phoneNumberUpdated={phoneNumberUpdated}
            freightCollectOptionUpdated={freightCollectOptionUpdated}
            deliveryInstructionsUpdated={deliveryInstructionsUpdated}
            deliveryInstructions2Updated={deliveryInstructions2Updated}
            commentsUpdated={commentsUpdated}
            carriersUpdated={carriersUpdated}
            freightOptionUpdated={freightOptionUpdated}
            selectedFreightOptions={selectedFreightOptions}
            />

    )
}

export default withRouter(CartReviewOrder);